import React from 'react'
import Stripe from '../components/Stripe'
import ProductSearch from '../components/ProductSearch'

function Test() {
  return (
    <div>
        <ProductSearch />
    </div>
  )
}

export default Test
